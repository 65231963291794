<template>
    <form @submit.prevent="submit($event)" class="border shadow-sm rounded form-login bg-white pb-5">
        <h3 class="fw-normal text-center mb-4 pb-2">Sign in</h3>

        <div class="form-group mb-4">
            <input
                type="email"
                class="form-control"
                id="email"
                name="email"
                placeholder="Email Address"
                v-model="email"
                @keydown="hideErrors"
            />
            <small id="emailHelp" class="form-text text-muted"
                >We'll never share your email with anyone else.</small
            >
        </div>
        <div class="form-group mb-4">
            <input
                type="password"
                id="pass"
                name="pass"
                class="form-control"
                placeholder="Password"
                v-model="pass"
                @keydown="hideErrors"
            />
        </div>

        <div class="form-group mb-4">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="customRadio1" name="customRadio" class="custom-control-input me-2">
                <label class="custom-control-label" for="customRadio1">Remember me</label>
            </div>
        </div>


        <p class="alert alert-warning mb-4" v-html="errors" v-if="errors.length"></p>

        <div class="form-group d-grid mb-4">
            <button
                type="submit"
                class="btn btn-primary btn-lg btn-block"
                @click="submit($event)"
            >
                Sign in
            </button>
        </div>




        <div class="form-group mb-4" >
            <p class="text-muted mb-1 pt-2">
                <a href="#"
                    @click.prevent="forgotPassword">Forgot Password?</a>
            </p>
            <p class="text-muted">
                <span>Don't have an account? </span><a :href="$root?.partner?.resources?.signup || '#'">Sign up</a>
            </p>
        </div>


        <div class=" login-divider position-relative pt-2 text-center mb-4">
            <span class="or bg-light border d-inline-block lh-lg rounded-circle"> 
            OR
            </span>
        </div>


        <div class="form-group" :style="{width: '70%'}">
            <div>
                <a class="btn btn-social text-secondary d-inline-block py-2 w-100 mx-5 my-2 text-start ps-3" href="#"
                    title="Sign in with your Google Email"
                    @click.prevent="signInWith('google')"
                    >

                    <img class="me-3" src="/resources/images/signin/google-logo-28x28.png"/>

                    <span class="btn-label position-absolute">
                        Sign with Google
                    </span>
                    
                </a>
            </div>
            <!--
            <div>
                <a class="btn btn-social text-secondary d-inline-block py-2 w-100 mx-5 my-2 text-start ps-3 border-color-light disabled" href="#"
                    title="Sign in with your LinkedIn Email"
                    @click.prevent="signInWith('linkedin')"
                    >

                    <img class="me-3 black-and-white-img" src="/resources/images/signin/linkedin-logo-28x28.png"/>

                    <span class="btn-label position-absolute">
                        Sign with LinkedIn
                    </span>
                </a>
            </div>

            <div>
                <a class="btn btn-social text-secondary d-inline-block py-2 w-100 mx-5 my-2 text-start ps-3 border-color-light disabled" href="#"
                    title="Sign in with your Facebook Email"
                    @click.prevent="signInWith('facebook')"
                    >

                    <img class="me-3 black-and-white-img" src="/resources/images/signin/facebook-logo-28x28.png"/>

                    <span class="btn-label position-absolute">
                        Sign with Facebook
                    </span>
                </a>
            </div>
            -->
        </div>

    </form>
</template>

<script>


export default {
  name: "Login",
  components: {
  },

    data(){
        return {
            URLS: {
                SAPI_REMOTE: 'https://sapi.cloud',
                SAPI_LOCALHOST: 'http://localhost:9090'
            },
            email: '',
            pass: '', 
            // emits: {
            //     LOGIN_SUCCESS: 'onloginsuccess',
            //     LOGIN_ERROR: 'onloginerror'
            // }
        }
    }, 


    computed: {

        user() {
            return this.$store.state.user;
        },


        errors() {
            return this.$store.state.errors.signOn;
        }
    },


    methods: {
        /**
         * Submits login form.
         * 
         * IMPORTANT: if credentials are valid, the user will be logged in and the user object will be
         * set in the applications ($root.user). Once the data changes whe application will redirect
         * to the specified default view. 
         * 
         * @param {Object} event
         * 
         * @see $root.user
         * @see $root.watch 
         */
        async submit(event) {
            event.preventDefault();
            let creds = {
                user_login: this.email,
                user_password: this.pass,
                remember: true
            };

            await this.$store.dispatch('signOn', creds);
        }, 



        /**
         * 
         * 
         * @return {object} user
         *          {object} user.ID This is "null" is signon fails
         *          {string} user.email
         *          {string} user.user_login This is the "username"
         *          {string} user.user_registered
         *          {string} user.display_name
         *          {array} user.roles The diferent user roles in Wordpress
         * 
         * 
         */
        // async signOn(creds, safeCookie) {
        //     safeCookie = safeCookie || false;
        //     let userResponse = await this.$root.$db.wp.wp_signon(creds, safeCookie);

        //     if('success' in userResponse && userResponse.success === false) {
        //         userResponse = {
        //             ID: null,
        //             errors: userResponse.message
        //         }
        //     }
        //     return userResponse;
        // },


        showErrors(errors) {
            this.errors = errors.join('; ');
        }, 

        hideErrors() {
            this.errors = '';
        }, 


        // invitation() {
        //     let url = 'https://coreoffer.io?invitation';
        //     let winName = String(new Date());
        //     window.open(url, winName);
        // },

        forgotPassword() {
            this.$root.resetPasswordIntent();
        },

        sapiURL(provider) {
            let baseURL = this.URLS.SAPI_REMOTE;
            let keyName = document.location.host; // e.g. app.coreoffer.io, salesmachine.app
            if(/^localhost/.test(document.location.host)) {
                baseURL = this.URLS.SAPI_LOCALHOST;
                keyName = 'localhost';
            }

            let url = `${baseURL}/${provider}/?mount=${keyName}.abc123.6cc5c51b9e6edc308782c2f115c7a049`;
            return url;
        },


        signInWith(provider) {
            /**
             * Store the current URL inside the browser session. This will 
             * allow the authentication page to know where to rederect an
             * authenticated user if the authentication process was successfully 
             * completed.
             * 
             * @see README.md for information about "Google Authentication Flow"
             */
            let currentPath = this.$route.fullPath;
            if(window?.sessionStorage) {
                window.sessionStorage.setItem('redirect_to', currentPath);
            }

            /**
             * Go to the Google Authentication page.
             */
            let authURL = this.sapiURL(provider);
            document.location.href = authURL;
            console.log(authURL)
        }

    }, 
};
</script>

<style scoped>

.black-and-white-img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.4;
}


input[type="email"],
input[type="text"],
input[type="password"] {
    outline: none;
    width: 100%;
    padding: 14px 20px;
    font-size: 18px;
    line-height: 20px;
    outline: 0;
    letter-spacing: 0px;
    color: #616161 !important;
    height: 50px;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}


form {
    padding: 40px 50px 20px;
}

.or {
    width: 32px;
    height: 32px;
}


.login-divider {
    z-index: 1;
}
.login-divider::before {
    content: '';
    display: block;
    width: 100%;
    border-top: 1px solid #dee2e6!important;
    margin-top: 16px;
    position: absolute;
    z-index: -1;
}

.btn-sign-in img {
    width: 65%;
}

.btn-social .btn-label {
    line-height: 28px;
}
.btn-social {
    border: 1px solid #958e8e8c;
}
.btn-social:hover {
    border-color: #3f3c3c8c;
    color: #333333c4 !important;
}

</style>
